import { MaquinaSelecionada, Apontamentos, Apontamento, Message, Funcionario, ApontamentoAtual } from './../app/common/interfaceApp';
import { defineStore } from 'pinia';

declare let frappe: any;

export const useAppStore = defineStore({
  id: 'app',
  state: (): {
    company: String,
    maquina_sel: MaquinaSelecionada,
    apontamento_atual: ApontamentoAtual,
    apontamentos_historico: Apontamentos[],
    view_apontamento_historico: boolean,
    showModalHistorico: boolean,
    showModalObsDetalhes: boolean,
    showModalMotivoDeParada: boolean,
    filtro_apontamentos_faltando_informacoes: Boolean,
    message: Message
  } => ({
    company: "GRV Software",
    maquina_sel: {
      id: null,
      leitor: null,
      nome: null,
      operador_padrao: null,
      tipo_apontamento: null,
      apontamentos: [],
      state_sensor: null,
      disponibilidade_diaria: 0
    },

    apontamento_atual: {
      name: null,
      funcionario: {
        identificacao: null,
        name_entidade: null,
        funcionario: null,
        foto: null,
        nome: null,
        name: null
      },
      op: null,
      observacao: null,
      dt_inicio: null,
      status: null,
      tipo: null,
      serial: null,
      turno: null,
      desc_motivo_parada: null,
      state_sensor: null,
    },
    apontamentos_historico: [],
    view_apontamento_historico: false,
    showModalHistorico: false,
    showModalObsDetalhes: false,
    showModalMotivoDeParada: false,
    filtro_apontamentos_faltando_informacoes: false,
    message: {
      showMessage: false,
      message: '',
      color: 'secondary'
    }
  }),

  getters: {},
  actions: {
    set_gauge(percent: number) {
      this.maquina_sel.disponibilidade_diaria = percent;
    },
    async reset_app() {
      this.apontamentos_historico = []
      this.maquina_sel = {
        id: null,
        leitor: null,
        nome: null,
        operador_padrao: null,
        tipo_apontamento: null,
        apontamentos: [],
        state_sensor: null,
        disponibilidade_diaria: 0
      }


      this.apontamento_atual = {
        name: null,
        funcionario: {
          identificacao: null,
          name_entidade: null,
          funcionario: null,
          foto: null,
          nome: null,
          name: null
        },
        op: null,
        observacao: null,
        dt_inicio: null,
        status: null,
        tipo: null,
        serial: null,
        turno: null,
        desc_motivo_parada: null,
        state_sensor: null,
      }
    },

    async filtrar_apontamentos(faltando_informacoes = false, limit = 15) {
      try {
        const res = await frappe.call(
          "nxlite.nx_producao.page.apontamento_iot.controller.get_all_recurso",
          {
            limit: limit,
            recurso: this.maquina_sel.id
          }
        );

        if ((faltando_informacoes) && (!this.view_apontamento_historico)) {
          const apontamentoAberto = res.message[0].apontamentos.find((apontamento: Apontamentos) => apontamento.status === "Aberto");

          if (apontamentoAberto) { useAppStore().set_apontamento_realtime(apontamentoAberto); }

          useAppStore().apontamentos_historico = res.message[0].apontamentos.filter((apontamento: Apontamentos) => {
            if (apontamento.status === "Aberto") { return false; }
            if (apontamento.tipo === "Produção") { return !apontamento.ordem_de_producao || !apontamento.nome_operador; }
            if (apontamento.tipo === "Parada") { return !apontamento.desc_motivo_parada; }

            return true;
          });

        }

        else {
          useAppStore().apontamentos_historico = []

          const apontamentoAberto = res.message[0].apontamentos.find((apontamento: Apontamentos) => apontamento.status === "Aberto");

          if (apontamentoAberto) {
            useAppStore().set_apontamento_realtime(apontamentoAberto);
          }

          useAppStore().apontamentos_historico = res.message[0].apontamentos.filter((apontamento: Apontamentos) => apontamento.status !== "Aberto")
        }
      } catch (error) { throw new Error(" Erro ao filtrar apontamentos " + error); }
    },


    set_apontamento_realtime(apontamento: Apontamentos) {
      this.apontamento_atual.name = apontamento.name
      this.apontamento_atual.funcionario.nome = apontamento.nome_operador
      this.apontamento_atual.op = apontamento.ordem_de_producao
      this.apontamento_atual.observacao = apontamento.observacao
      this.apontamento_atual.dt_inicio = apontamento.dt_inicio
      this.apontamento_atual.status = apontamento.status
      this.apontamento_atual.tipo = apontamento.tipo
      this.apontamento_atual.serial = apontamento.serial
      this.apontamento_atual.turno = apontamento.turno
      this.apontamento_atual.desc_motivo_parada = apontamento.desc_motivo_parada
      this.apontamento_atual.state_sensor = apontamento.status_sensor
      this.apontamento_atual.motivo_de_parada_manutencao_setup = apontamento.motivo_de_parada_manutencao_setup
    },

    selecionar_op(op: any) { this.apontamento_atual.op = op.name },

    async selecionar_maquina(maquina: MaquinaSelecionada) {
      const disponibilidade_diaria = await frappe.db.get_value('Disponibilidade por turno', `${maquina.id}-${this.apontamento_atual.turno}: ${frappe.datetime.get_today()}`, 'percentual_producao')

      this.apontamentos_historico = []

      console.log("maquina selecionada",maquina)
      this.maquina_sel = maquina

      this.maquina_sel.disponibilidade_diaria = (typeof disponibilidade_diaria.message.percentual_producao === 'number') ? Number(disponibilidade_diaria.message.percentual_producao.toFixed(2)) : 0;

      if (Array.isArray(maquina.apontamentos)) {
        const apontamentosMapeados: Apontamento[] = maquina.apontamentos.map((apontamento: Partial<Apontamento>) => {
          return {
            ...apontamento,
            name_entidade: apontamento.name_entidade || null,
            op: apontamento.op || null,
            funcionario: apontamento.funcionario || null
          } as Apontamento;
        });

        const apontamentoAberto = apontamentosMapeados.find((apontamento: Apontamento) => apontamento.status === "Aberto");

        if (apontamentoAberto) { this.set_apontamento_realtime(apontamentoAberto); }
        this.apontamentos_historico = apontamentosMapeados.filter((apontamento: Apontamento) => apontamento.status !== "Aberto");

      }
    },

    selecionar_funcionario(funcionario: Funcionario) { this.apontamento_atual.funcionario = funcionario },

    async get_all_filter_by_date_apontamento(dt_ini: any, dt_fim: any) {
      let apt = await frappe.call({
        method: 'nxlite.nx_producao.page.apontamento_iot.controller.get_all_filter_by_date_apontamento',
        args: {
          start_date: dt_ini,
          end_date: dt_fim
        },
      });

      let apts: any = {};

      if (Array.isArray(apt.message)) {
        apt.message.forEach((apontamento: Apontamento) => {
          apts = {
            ...apts,
            apontamento: {
              name: apontamento.name,
              nome_operador: apontamento.nome_operador,
              recurso: apontamento.recurso,
              desc_recurso: apontamento.desc_recurso,
              dt_inicio: apontamento.dt_inicio,
              dt_fim: apontamento.dt_fim,
              ordem_de_producao: apontamento.ordem_de_producao,
              status: apontamento.status,
              observacao: apontamento.observacao,
              tipo: apontamento.tipo,
              serial: apontamento.serial,
              motivo_de_parada: apontamento.motivo_de_parada,
              desc_motivo_parada: apontamento.desc_motivo_parada,
              origem_apontamento: apontamento.origem_apontamento,
              turno: apontamento.turno,
              status_sensor: apontamento.status_sensor
            }
          };
        });
      }

      return apts;
    }, persist: { enabled: true }
  }
})